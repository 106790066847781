.Polaris-Page-Header {
  padding-bottom: 0;
}

.order-date {
  width: fit-content;
  padding: 5px 0 10px;
}

.empty-order-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
